:root {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: var(--link);
  border-bottom: 1px solid;
}

body {
  background: var(--background);
  color: var(--text);
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  min-height: 100vh;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.15;
}

h1 {
  font-size: 3em;
  line-height: 1.15;
  margin: 0;
}

article,
body > footer {
  max-width: 720px;
  margin: 0 auto;
  padding: 0 1em;
}
body > footer {
  position: sticky;
  top: 100vh;

  font-size: 14px;
  font-style: italic;
  padding: 4em 10px;
}

pre {
  counter-reset: code;
  white-space: normal;
}
pre code {
  display: block;
  white-space: pre;
}
code {
  font-size: 14px;
  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
}

pre.snippet {
  margin: 1em -1em;
  padding: 14px 1em;
  --ac1: #312e81;
  --ac2: #3730a3;
  background: linear-gradient(to right, var(--ac1) 0%, var(--ac1) 30%, transparent),
    repeating-linear-gradient(135deg, var(--ac1), var(--ac1) 10px, var(--ac2) 10px, var(--ac2) 20px);
  color: white;
}

header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4em 10% 4em 0;
}
header a:last-child {
  order: -1;
  border: none;
  font-size: 14px;
}
header p {
  margin: 0;
}

section:nth-of-type(n + 2) > h2:first-child {
  margin-top: 2em;
}

.status-complete,
.status-wip {
  position: relative;
}
.status-complete::after {
  color: green;
}
.status-wip::after {
  color: red;
}
.status-complete::after,
.status-wip::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin: 1px 4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 2px solid;
}

.toc li small {
  font-style: italic;
}

.cards {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1em;
  row-gap: 2em;
}
.cards > li {
  flex-basis: 200px;
  flex-shrink: 0;
  flex-grow: 1;
}
.cards a {
  font-size: 18px;
}

.fade-enter {
  animation: fadein 0.2s ease;
}

.fade-leave {
  animation: fadeout 0.2s ease;
}

@keyframes fadein {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
}

@keyframes fadeout {
  to {
    transform: translateY(10px);
    opacity: 0;
  }
}

.message {
  margin: 1em -1em;
  padding: 14px 1em;
  background: linear-gradient(to right, var(--ac1) 0%, var(--ac1) 30%, transparent),
    repeating-linear-gradient(135deg, var(--ac1), var(--ac1) 10px, var(--ac2) 10px, var(--ac2) 20px);
  color: rgb(250, 250, 250);
}
.message > strong:first-child::after {
  content: "\00000a";
  white-space: pre-wrap;
}
.message.info {
  --ac1: #2563eb;
  --ac2: #3b82f6;
}
.message.warning {
  --ac1: #eab308;
  --ac2: #facc15;
}
.message.error {
  --ac1: #dc2626;
  --ac2: #ef4444;
}

article .sequence {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
}
.sequence-link {
  display: flex;
  flex-direction: column;
}
.sequence-link.prev {
  align-items: flex-start;
}
.sequence-link.next {
  align-items: flex-end;
}
.sequence-link.prev small::before {
  content: "\2190";
  display: inline-block;
  padding-right: 0.15em;
}
.sequence-link.next small::after {
  content: "\2192";
  display: inline-block;
  padding-left: 0.15em;
}
