:root {
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

a:link, a:visited, a:hover, a:active {
  color: var(--link);
  border-bottom: 1px solid;
  text-decoration: none;
}

body {
  background: var(--background);
  color: var(--text);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
}

html, body {
  min-height: 100vh;
}

h1, h2, h3 {
  margin: 0;
  line-height: 1.15;
}

h1 {
  margin: 0;
  font-size: 3em;
  line-height: 1.15;
}

article, body > footer {
  max-width: 720px;
  margin: 0 auto;
  padding: 0 1em;
}

body > footer {
  padding: 4em 10px;
  font-size: 14px;
  font-style: italic;
  position: sticky;
  top: 100vh;
}

pre {
  counter-reset: code;
  white-space: normal;
}

pre code {
  white-space: pre;
  display: block;
}

code {
  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
  font-size: 14px;
}

pre.snippet {
  --ac1: #312e81;
  --ac2: #3730a3;
  background: linear-gradient(to right, var(--ac1) 0%, var(--ac1) 30%, transparent), repeating-linear-gradient(135deg, var(--ac1), var(--ac1) 10px, var(--ac2) 10px, var(--ac2) 20px);
  color: #fff;
  margin: 1em -1em;
  padding: 14px 1em;
}

header {
  flex-direction: column;
  align-items: flex-start;
  padding: 4em 10% 4em 0;
  display: flex;
}

header a:last-child {
  border: none;
  order: -1;
  font-size: 14px;
}

header p {
  margin: 0;
}

section:nth-of-type(n+2) > h2:first-child {
  margin-top: 2em;
}

.status-complete, .status-wip {
  position: relative;
}

.status-complete:after {
  color: green;
}

.status-wip:after {
  color: red;
}

.status-complete:after, .status-wip:after {
  content: "";
  width: 7px;
  height: 7px;
  border: 2px solid;
  border-radius: 50%;
  margin: 1px 4px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.toc li small {
  font-style: italic;
}

.cards {
  flex-flow: wrap;
  gap: 2em 1em;
  padding: 0;
  list-style: none;
  display: flex;
}

.cards > li {
  flex: 1 0 200px;
}

.cards a {
  font-size: 18px;
}

.fade-enter {
  animation: .2s fadein;
}

.fade-leave {
  animation: .2s fadeout;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
}

@keyframes fadeout {
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.message {
  background: linear-gradient(to right, var(--ac1) 0%, var(--ac1) 30%, transparent), repeating-linear-gradient(135deg, var(--ac1), var(--ac1) 10px, var(--ac2) 10px, var(--ac2) 20px);
  color: #fafafa;
  margin: 1em -1em;
  padding: 14px 1em;
}

.message > strong:first-child:after {
  content: "\a ";
  white-space: pre-wrap;
}

.message.info {
  --ac1: #2563eb;
  --ac2: #3b82f6;
}

.message.warning {
  --ac1: #eab308;
  --ac2: #facc15;
}

.message.error {
  --ac1: #dc2626;
  --ac2: #ef4444;
}

article .sequence {
  justify-content: space-between;
  margin: 1em 0;
  display: flex;
}

.sequence-link {
  flex-direction: column;
  display: flex;
}

.sequence-link.prev {
  align-items: flex-start;
}

.sequence-link.next {
  align-items: flex-end;
}

.sequence-link.prev small:before {
  content: "←";
  padding-right: .15em;
  display: inline-block;
}

.sequence-link.next small:after {
  content: "→";
  padding-left: .15em;
  display: inline-block;
}

/*# sourceMappingURL=index.f9be00c7.css.map */
